import React from "react";

import "./styles_main.css";
import "./assets/bg.webp";
import HeaderMenu from "./HeaderMenu";

class MainPage extends React.Component {
  render() {
    return (
      <div className="content_container">
        <HeaderMenu />
        <div className="intro__content">
          <img className="intro__img" alt="selfie" src="/images/selfie.png" />
          <p className="intro__sub-title">FRONT-END DEVELOPER</p>

          <h1 className="intro__title">Katerina Bezlepkina</h1>
          <div className="intro-text__container">
            <p className="intro-text__item">Javascript</p>
            <p className="intro-text__item">React/Redux</p>
          </div>
        </div>
      </div>
    );
  }
}

export default MainPage;
