import React from "react";
import { NavLink } from "react-router";
import "./styles_main.css";

class HeaderMenu extends React.Component {
  render() {
    return (
      <header className="header">
        <div className="content-container">
          <div className="header__content">
            <NavLink
              to="/"
              className={({ isActive }) => {
                return isActive ? "header_link selected" : "header_link";
              }}
            >
              Home
            </NavLink>

            <NavLink
              to="/about-me"
              className={({ isActive }) =>
                isActive ? "header_link selected" : "header_link"
              }
            >
              About Me
            </NavLink>

            <NavLink
              to="/contacts"
              className={({ isActive }) =>
                isActive ? "header_link selected" : "header_link"
              }
            >
              Contacts
            </NavLink>
          </div>
        </div>
      </header>
    );
  }
}

export default HeaderMenu;
