import React from "react";
import HeaderMenu from "./HeaderMenu";

const AboutMe = () => (
  <div className="content_container">
    <HeaderMenu />
    <div className="about-me-text__content">
      <p className="about-me-item__title">Katerina Bezlepkina</p>
      <p className="about-me__sub-title">FRONT-END DEVELOPER</p>

      <p className="about-me-text__item">
        For more than half a decade, I am working with international teams in
        different timezones. I had hands on experience in working with libraries
        like React.js (Redux, hooks + context API), NextJs, VueJs, testing
        suites Jest & Mocha, E2E tests frameworks like Playwright and Cypress,
        creating layouts, UI design, supporting and troubleshooting web
        applications.
      </p>

      <p className="about-me-text__item">
        Except JavaScript, I am also fluent in human languages such as Russian,
        English & Mandarin Chinese. I also speak basic Swedish and Crotain.
      </p>

      <p className="about-me-text__item">
        Outside programming I enjoy music, consuming huge amounts of caffeine &
        city walks.
      </p>
    </div>
  </div>
);

export default AboutMe;
