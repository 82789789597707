import React from "react";

import "./styles_main.css";
import HeaderMenu from "./HeaderMenu";

const Contacts = () => (
  <div className="content_container">
    <HeaderMenu />
    <div className="contacts__content">
      <div className="contacts-item__box">
        <p className="contacts-item__title">Ways to contact me:</p>

        <p className="contacts-item__text">
          <i
            className="fab fa-linkedin-in contacts-icon-spacing"
            aria-hidden="true"
          ></i>
          <a
            className="contacts-item__link"
            href="https://www.linkedin.com/in/ekaterinabezlepkina/"
            target="_blank"
            rel="noopener noreferrer"
          >
            LinkedIn
          </a>
        </p>

        <p className="contacts-item__text">
          <i
            className="fab fa-github-alt contacts-icon-spacing"
            aria-hidden="true"
          ></i>
          <a
            className="contacts-item__link contact-icon-spacing"
            href="https://github.com/Arkeetina"
            target="_blank"
            rel="noopener noreferrer"
          >
            GitHub
          </a>
        </p>
      </div>
    </div>
  </div>
);

export default Contacts;
