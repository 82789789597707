import React from "react";
import { Link } from "react-router";
import "./styles_main.css";

const NotFoundPage = () => (
  <div className="content_container">
    <div className="not-found__content">
      <div className="not-found-item__box">
        <p className="not-found-item__text">404 No Such Page Found Here</p>
        <p className="not-found-item__link">
          <Link className="not-found-item-link-color" to="/">
            Go home
          </Link>
        </p>
      </div>
    </div>
  </div>
);

export default NotFoundPage;
