import React from "react";
import { Route, Routes, BrowserRouter } from "react-router";

import MainPage from "../components/MainPage";
import NotFoundPage from "../components/NotFoundPage";
import Contacts from "../components/Contacts";
import AboutMe from "../components/AboutMe";
import "./styles.css";
const AppRouter = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<MainPage />} />
      <Route path="/contacts" element={<Contacts />} />
      <Route path="/about-me" element={<AboutMe />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  </BrowserRouter>
);

export default AppRouter;
